import {
  ArchiveBoxIcon,
  ArrowPathIcon,
  ArrowRightIcon,
  ChevronRightIcon,
  CircleStackIcon,
  CpuChipIcon,
  RocketLaunchIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline'

import animationData from '../lottie/landing-hero.json'
import Heading from '~/core/ui/Heading'
import Divider from '~/core/ui/Divider'
import SubHeading from '~/core/ui/SubHeading'
import CalendlyWidget from '~/components/calendly/Calendly'
import Button from '~/core/ui/Button'
import Container from '~/core/ui/Container'
import { ProgressiveClientOnly } from '~/components/ProgressiveClientOnly'
import { LottiePlayer } from '~/components/LottiePlayer'

export default function Index() {
  return (
    <div className="flex flex-col space-y-16">
      <Container>
        <div
          className={
            'my-12 flex flex-col items-center md:flex-row lg:my-16'
            + ' mx-auto flex-1 justify-center animate-in fade-in '
            + ' duration-1000 slide-in-from-top-12'
          }
        >
          <div className="flex w-full flex-1 flex-col items-center space-y-8">
            <Pill>
              <span>The leading SaaS tools for ambitious researchers</span>
            </Pill>

            <HeroTitle>
              <span>Transform Market Research</span>
              <span
                className={
                  'bg-gradient-to-br bg-clip-text text-transparent'
                  + ' from-primary-400 to-primary-700 leading-[1.2]'
                }
              >
                with the power of AI
              </span>
            </HeroTitle>

            <SubHeading className="text-center">
              <span>Empower your research with Talio and transform</span>
              <span>weeks of work into just minutes of analysis.</span>
            </SubHeading>

            <div className="flex flex-col items-center space-y-4">
              <MainCallToActionButton href="#contact" />

              <span className="text-xs text-gray-500 dark:text-gray-400">
                Free trial available. No credit card required.
              </span>
            </div>
          </div>
        </div>

        <div
          className={
            'flex justify-center max-w-5xl mx-auto animate-in fade-in '
            + ' duration-1000 slide-in-from-top-16 fill-mode-both delay-300'
          }
        >
          <ProgressiveClientOnly>
            <LottiePlayer
              animationData={animationData}
              loop={true}
              className={
                'mx-auto h-full'
                + ' animate-in fade-in'
                + ' zoom-in-50 delay-300 duration-1000 ease-out fill-mode-both'
              }
            />
          </ProgressiveClientOnly>
        </div>
      </Container>

      <Container>
        <div className="flex flex-col items-center justify-center space-y-24 pb-16 pt-3">
          <div className="flex max-w-3xl flex-col items-center space-y-6 text-center">
            <Pill>Scalable and secure</Pill>

            <div className="flex flex-col space-y-2.5">
              <Heading type={2}>The Ultimate Analysis Tool</Heading>

              <SubHeading as="h3">
                Unbeatable Features and Benefits for Your Research Agency
              </SubHeading>
            </div>
          </div>

          <div>
            <div className="grid grid-cols-2 gap-12 lg:grid-cols-3">
              <div className="flex flex-col space-y-2">
                <FeatureIcon>
                  <SparklesIcon className="h-5" />
                </FeatureIcon>

                <h4 className="text-lg font-semibold">AI powered Coding</h4>

                <div className="text-sm text-gray-500 dark:text-gray-400">
                  Automatically categorize thousands of responses with
                  precision, turning weeks of manual work into minutes.
                </div>
              </div>

              <div className="flex flex-col space-y-2">
                <FeatureIcon>
                  <ArrowPathIcon className="h-5" />
                </FeatureIcon>

                <h4 className="text-lg font-semibold">Co-Pilot</h4>

                <div className="text-sm text-gray-500 dark:text-gray-400">
                  Our easy to edit tags enable you to add or remove tags that
                  were generated by the AI
                </div>
              </div>

              <div className="flex flex-col space-y-2">
                <FeatureIcon>
                  <RocketLaunchIcon className="h-5" />
                </FeatureIcon>

                <h4 className="text-lg font-semibold">Project Acceleration</h4>

                <div className="text-sm text-gray-500 dark:text-gray-400">
                  Speed up your entire market research process, from data
                  collection to insight generation.
                </div>
              </div>

              <div className="flex flex-col space-y-2">
                <FeatureIcon>
                  <CircleStackIcon className="h-5" />
                </FeatureIcon>

                <h4 className="text-lg font-semibold">Data Synthesis</h4>

                <div className="text-sm text-gray-500 dark:text-gray-400">
                  Merge and make sense of disparate data sources, building a
                  comprehensive narrative with ease.
                  {' '}
                </div>
              </div>

              <div className="flex flex-col space-y-2">
                <FeatureIcon>
                  <ArchiveBoxIcon className="h-5" />
                </FeatureIcon>

                <h4 className="text-lg font-semibold">
                  Historical Data Leverage
                </h4>

                <div className="text-sm text-gray-500 dark:text-gray-400">
                  Turn your archives into an asset by extracting new insights
                  from years of accumulated research data.
                  {' '}
                </div>
              </div>

              <div className="flex flex-col space-y-2">
                <FeatureIcon>
                  <CpuChipIcon className="h-5" />
                </FeatureIcon>

                <h4 className="text-lg font-semibold">Continuous Learning</h4>

                <div className="text-sm text-gray-500 dark:text-gray-400">
                  Our AI grows smarter with each analysis, delivering
                  ever-improving results and refined insights.
                  {' '}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <div className="flex flex-col space-y-4">
          <FeatureShowcaseContainer>
            <LeftFeatureContainer>
              <div className="flex flex-col space-y-4">
                <Heading type={2}>AI Transcription</Heading>

                <SubHeading as="h3">
                  An AI Co-Pilot For Your Focus Groups and Interviews
                </SubHeading>
              </div>

              <div>
                Revolutionise your research with our AI Transcription feature.
                Our smart bot can seamlessly join your live calls or process
                your uploaded audio and video files, capturing every speaker in
                detail.
                {' '}
                <br />
                Experience rapid, detailed transcripts, insightful summaries,
                and key takeaways delivered by our advanced AI.
                <br />
                {' '}
              </div>

              <div>
                <Button href="#contact" round variant="outline">
                  <span className="flex items-center space-x-2">
                    <span>Book a call</span>
                    <ChevronRightIcon className="h-3" />
                  </span>
                </Button>
              </div>
            </LeftFeatureContainer>

            <RightFeatureContainer>
              <img
                loading="lazy"
                className="rounded-2xl"
                src="/assets/images/transcribe.png"
                width="626"
                height="683"
                alt="team"
              />
            </RightFeatureContainer>
          </FeatureShowcaseContainer>

          <FeatureShowcaseContainer>
            <LeftFeatureContainer>
              <img
                loading="lazy"
                className="rounded-2xl"
                src="/assets/images/topics-example.png"
                width="887"
                height="743"
                alt="Dashboard"
              />
            </LeftFeatureContainer>

            <RightFeatureContainer>
              <div className="flex flex-col space-y-4">
                <Heading type={2}>AI-Powered Coding</Heading>

                <SubHeading as="h3">
                  Analyze Open-Ended Questions with Precision
                </SubHeading>

                <div>
                  Leverage AI to automatically categorise responses by topics
                  and sentiment, drastically reducing manual work. Our
                  AI-powered coding feature turns weeks of effort into minutes,
                  delivering precise results for efficient market research and
                  data analysis.
                  {' '}
                </div>

                <div>
                  <Button href="#contact" round variant="outline">
                    <span className="flex items-center space-x-2">
                      <span>Book a call</span>
                      <ChevronRightIcon className="h-3" />
                    </span>
                  </Button>
                </div>
              </div>
            </RightFeatureContainer>
          </FeatureShowcaseContainer>
          <FeatureShowcaseContainer>
            <LeftFeatureContainer>
              <div className="flex flex-col space-y-4">
                <Heading type={2}>New Capabilities - Coming Soon</Heading>

                <SubHeading as="h3">
                  Next-Gen Tools to Revolutionise Your Market Research Process
                </SubHeading>

                <div>
                  Talio’s upcoming features are set to revolutionise your market
                  research efforts forever. These features are designed to
                  optimise every phase of your workflow.
                </div>

                <div>
                  <Button href="#contact" round variant="outline">
                    <span className="flex items-center space-x-2">
                      <span>Book a call</span>
                      <ChevronRightIcon className="h-3" />
                    </span>
                  </Button>
                </div>
              </div>
            </LeftFeatureContainer>

            <RightFeatureContainer>
              <img
                loading="lazy"
                className="rounded-2xl"
                src="/assets/images/features.png"
                width="887"
                height="743"
                alt="Dashboard"
              />
            </RightFeatureContainer>
          </FeatureShowcaseContainer>
        </div>
      </Container>

      <Divider />
      <Container>
        <div className="py-8 sm:py-8">
          <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
            <div className="relative overflow-hidden bg-gray-900 px-6 py-20 shadow-xl sm:rounded-3xl sm:px-10 sm:py-24 md:px-12 lg:px-20">
              <img
                className="absolute inset-0 size-full object-cover brightness-150 saturate-0"
                src="/assets/images/gerard-testimonial.png"
                alt=""
              />
              <div className="absolute inset-0 bg-gray-900/90 mix-blend-multiply" />
              <div
                className="absolute -left-80 -top-56 transform-gpu blur-3xl"
                aria-hidden="true"
              >
                <div className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[#A71931] to-[#000] opacity-45" />
              </div>
              <div
                className="hidden md:absolute md:bottom-16 md:left-[50rem] md:block md:transform-gpu md:blur-3xl"
                aria-hidden="true"
              >
                <div
                  className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[#A71931] to-[#000]  opacity-25"
                  style={{
                    clipPath:
                      'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                  }}
                />
              </div>
              <div className="relative mx-auto max-w-2xl lg:mx-0">
                <img
                  className="h-20 w-auto"
                  src="/assets/images/amarach-logo.png"
                  alt=""
                />
                <figure>
                  <blockquote className="mt-6 text-lg font-semibold text-white sm:text-xl sm:leading-8">
                    <p>
                      "The tools that have been developed enable us to be much
                      more confident about tightening up turnaround times,
                      committing to faster turnaround than before, but also
                      confident that the quality of the analysis and the insight
                      is second to none."
                    </p>
                  </blockquote>
                  <figcaption className="mt-6 text-base text-white">
                    <div className="font-semibold">Gerard O'Neill</div>
                    <div className="mt-1">Chairman of Amarach</div>
                  </figcaption>
                  <Button
                    className="-ml-4 mt-2 !pl-0 text-white"
                    variant="link"
                    href="blog/case-study-amarach"
                  >
                    Read the full case study
                    {' '}
                    <ArrowRightIcon className="ml-1 size-4" />
                  </Button>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </Container>
      <Divider />
      <Container>
        <BackedBy />
      </Container>

      <Divider />

      <div
        className="flex flex-col items-center justify-center space-y-16 py-16"
        id="contact"
      >
        <div className="flex flex-col items-center space-y-6 text-center">
          <Pill>AI powered research at your fingertips</Pill>

          <div className="flex flex-col space-y-2.5">
            <Heading type={2}>
              Ready to take your research to the next level?
            </Heading>

            <SubHeading as="h3">
              Schedule a call with us and let's redefine the future of market
              research together.
            </SubHeading>
            <ProgressiveClientOnly>
              <CalendlyWidget />
            </ProgressiveClientOnly>
          </div>
        </div>
      </div>
    </div>

  )
}

function BackedBy() {
  return (
    <div className="py-12 sm:py-12">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg font-semibold leading-8">
          Backed by
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-3 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-3 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <img
            className="col-span-1 max-h-12 w-full object-contain dark:hidden"
            src="/assets/images/dogpatch-black.png"
            alt="dogpatch labs"
            width={158}
            height={48}
          />
          <img
            className="col-span-1 hidden max-h-12 w-full object-contain dark:block"
            src="/assets/images/dogpatch-light.png"
            alt="dogpatch labs"
            width={158}
            height={48}
          />
          <img
            className="col-span-1 max-h-12 w-full object-contain dark:hidden"
            src="/assets/images/ei-black.png"
            alt="Enterprise Ireland"
            width={158}
            height={48}
          />
          <img
            className="col-span-1 hidden max-h-12 w-full object-contain dark:block"
            src="/assets/images/ei-white.png"
            alt="Enterprise Ireland"
            width={158}
            height={48}
          />
          <img
            className="col-span-1 max-h-12 w-full object-contain dark:hidden"
            src="/assets/images/founders-black.png"
            alt="Founders"
            width={158}
            height={48}
          />
          <img
            className="col-span-1 hidden max-h-12 w-full object-contain dark:block"
            src="/assets/images/founders-white.png"
            alt="Founders"
            width={158}
            height={48}
          />
        </div>
      </div>
    </div>
  )
}

function HeroTitle({ children }: React.PropsWithChildren) {
  return (
    <h1
      className={
        'text-center text-4xl text-gray-600 dark:text-white md:text-5xl'
        + ' flex flex-col font-heading font-medium xl:text-7xl 2xl:text-[5.2rem]'
      }
    >
      {children}
    </h1>
  )
}

function FeatureIcon(props: React.PropsWithChildren) {
  return (
    <div className="flex">
      <div
        className={
          'rounded-xl bg-primary/5 p-4 dark:bg-background border'
          + ' border-primary/5 dark:border-dark-800'
        }
      >
        {props.children}
      </div>
    </div>
  )
}

function Pill(props: React.PropsWithChildren) {
  return (
    <h2
      className={
        'inline-flex w-auto items-center space-x-2'
        + ' rounded-full bg-gradient-to-br dark:from-gray-200 dark:via-gray-400'
        + ' dark:to-gray-700 bg-clip-text px-4 py-2 text-center text-sm'
        + ' font-normal text-gray-500 dark:text-transparent shadow'
        + ' dark:shadow-dark-700'
      }
    >
      <span>{props.children}</span>
    </h2>
  )
}

function FeatureShowcaseContainer(props: React.PropsWithChildren) {
  return (
    <div
      className={
        'flex flex-col lg:flex-row items-center justify-between'
        + ' lg:space-x-24'
      }
    >
      {props.children}
    </div>
  )
}

function LeftFeatureContainer(props: React.PropsWithChildren) {
  return (
    <div className="flex w-full flex-col space-y-8 lg:w-6/12">
      {props.children}
    </div>
  )
}

function RightFeatureContainer(props: React.PropsWithChildren) {
  return <div className="flex w-full lg:w-6/12">{props.children}</div>
}

function MainCallToActionButton({ href }: any) {
  return (
    <Button
      className={
        'bg-transparent bg-gradient-to-r shadow-2xl'
        + ' hover:shadow-primary/60 from-sky-500'
        + ' to-sky-600 hover:to-sky-600 text-white'
      }
      variant="custom"
      size="lg"
      href={href}
    >
      <span className="flex items-center space-x-2">
        <span>Book a call</span>
        <ChevronRightIcon
          className={
            'h-4 animate-in fade-in slide-in-from-left-8'
            + ' delay-1000 fill-mode-both duration-1000 zoom-in'
          }
        />
      </span>
    </Button>
  )
}
